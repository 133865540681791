import React from "react";
import styled from "styled-components";
import * as Router from "next/router";
import Head from "next/head";

import * as gtag from "../utils/gtag";
import * as T from "./Typography";

export default function Error404() {
  const router = Router.useRouter();
  React.useEffect(recordUrl, [router.asPath]);

  return (
    <S.MissingContainer>
      <Head>
        <title>Page not found | AllBright</title>
      </Head>
      <S.Missing>
        <T.H2>404</T.H2>
        <T.CXL>This page could not be found.</T.CXL>
      </S.Missing>
    </S.MissingContainer>
  );

  function recordUrl() {
    if (!router.asPath || router.asPath === "/404") return;

    gtag.event({
      name: gtag.GA_EVENT.EXCEPTION,
      category: "EXCEPTION",
      label: "404",
      value: router.asPath,
    });
  }
}

const S = {};

S.MissingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 60px);
  @media (min-width: 900px) {
    min-height: calc(100vh - 84px);
  }
`;

S.Missing = styled.article`
  align-items: center;
  display: flex;
  justify-content: center;
  > * {
    padding: 1rem 2rem;
  }
  * + * {
    border-left: solid 1px black;
  }
`;
